
export function compareAnswers(userAnswer: string, correctAnswer: string): boolean {
    const articleRegex = /\b(a|an|the)\b/gi;
    const punctuationRegex = /[.,\/#!$%\^&\*;:{}=\-_`~()]/g;

    const formatString = (str: string) => {
        return str
            .toLowerCase()
            .replace(articleRegex, '')
            .replace(/\s/g, '')
            .replace(punctuationRegex, '');
    };

    const finalUserString = formatString(userAnswer);
    const correctAnswers = correctAnswer.split('$');

    for (const answer of correctAnswers) {
        const finalCorrectString = formatString(answer);
        if (finalUserString === finalCorrectString) {
            return true;
        }
    }

    return false;
}