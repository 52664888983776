import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useAppSelector } from "../../../redux/hook";
import { DownloadApp } from "../DownloadApp/DownloadApp";
import { EnteringName } from "./EnteringName/EnteringName";
import { Riddle } from "./Riddle/Riddle";
import iceCreamPuzzle from "../../../assets/image/Ice-cream-puzzle.png";
import styles from "./MainScreen.module.scss";

export const MainScreen = () => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const { restaurant, userName } = useAppSelector((state) => state.restaurant);

  return (
    <main className={styles.main}>
      <DownloadApp show={isShown} setShow={setIsShown} />
      <div
        className={`${styles.mainContainer} ${
          isShown ? styles.mainContainerPadding : null
        }`}
      >
        <h1 className={styles.restaurantsName}>{restaurant.restaurantName}</h1>
        <img
          src={iceCreamPuzzle}
          alt="ice cream puzzle"
          className={styles.mainImage}
        />
      </div>

      <AnimatePresence
        mode="wait"
        initial={false}
        onExitComplete={() => {
          window.scrollTo(0, 0);
        }}
      >
        <motion.div
          key={userName === "" ? "entering" : "riddle"}
          initial={{ x: 10, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -10, opacity: 0 }}
          transition={{ duration: 0.4 }}
        >
          {userName === "" ? <EnteringName /> : <Riddle />}
        </motion.div>
      </AnimatePresence>

      <p className={styles.bottomText}>
        Powered by <span>WhoRiddle</span>
      </p>
    </main>
  );
};
