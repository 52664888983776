import React, { useState } from "react";
import { motion } from "framer-motion";

import sprite from "../../../assets/sprite.svg";
import styles from "./ImageFlip.module.scss";
import { useAppSelector } from "../../../redux/hook";

const spring = {
  type: "spring",
  stiffness: 300,
  damping: 50,
};

// const imageKey = process.env.REACT_APP_HOST_CMS_DEV;

export const ImageFlip = () => {
  const [isFlipped, setIsFlipped] = useState<boolean>(false);

  const { rewardImage, rewardQr } = useAppSelector(
    (state) => state.restaurant.restaurant
  );

  // const handleClick = () => {
  //   setIsFlipped((prevState) => !prevState);
  // };
  return (
    <div>
      <motion.div
        // onClick={handleClick}
        transition={spring}
        className={styles.motion}
      >
        <div
          style={{
            perspective: "1200px",
            transformStyle: "preserve-3d",
            width: "100%",
            height: "100%",
          }}
        >
          <motion.div
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            <div className={styles.imageContainer}>
              <img
               src={rewardImage}
               //  src={`${imageKey}${rewardImage}`}
                alt="rewardImage"
                className={styles.mainImage}
              />

              {/*<svg className={styles.icon}>*/}
              {/*  <use href={sprite + "#qr-code"}></use>*/}
              {/*</svg>*/}
            </div>
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: isFlipped ? 0 : 180 }}
            transition={spring}
            style={{
              width: "100%",
              height: "100%",
              zIndex: isFlipped ? 1 : 0,
              backfaceVisibility: "hidden",
              position: "absolute",
            }}
          >
            {/* back */}
            <div className={styles.imageContainer}>
              <img
                src={rewardQr}
                // src={`${imageKey}${rewardQr}`}
                alt="qr-code"
                className={styles.mainImage}
              />

              {/*<svg className={styles.icon}>*/}
              {/*  <use href={sprite + "#image-icon"}></use>*/}
              {/*</svg>*/}
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};
