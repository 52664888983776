import styles from './Main.module.scss';
import riddleImage from '../../../assets/image/DownloadPage/RiddleImage.png';
import Header from '../Header/Header';
import downloadingArrow from '../../../assets/image/DownloadPage/downloadArrow.png';
import getDownloadLink from '../../../utils/getDownloadLink';

const Main = () => {
    const downloadLink = getDownloadLink();
    const openLink = () => {
        window.open(downloadLink, '_blank');
    };

    const isSafari =
        /^((?!chrome|android|crios|fxios).)*safari/i.test(navigator.userAgent) &&
        /Apple/.test(navigator.vendor);

    return (
        <div className={`${styles.main} ${isSafari ? styles.main_safari : ''}`}>
            <div>
                <Header />
                <div className={styles.wrapper}>
                    <img
                        src={riddleImage}
                        alt="riddle"
                        className={`${styles.riddleImage} ${
                            isSafari ? styles.safariImage : ''
                        }`}
                    />
                    <div className={styles.text}>
                        <div className={styles.title}>
                            Play, Solve, <br />
                            Win Rewards!
                        </div>

                        <div className={styles.subtitle}>
                            Join the ultimate riddle challenge <br />
                            and win amazing rewards!
                        </div>
                    </div>
                    <div onClick={openLink} className={styles.buttonWrapper}>
                        <div className={styles.button}>Download app</div>
                    </div>
                </div>
            </div>
            <div className={styles.downloadingArrow}>
                <img src={downloadingArrow} alt="downloadingArrow" />
            </div>
        </div>
    );
};

export default Main;
