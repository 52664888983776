import {APP_STORE_LINK, GOOGLE_PLAY_LINK} from '../constants/downloadLinks';

const getDownloadLink = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
        return GOOGLE_PLAY_LINK;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return APP_STORE_LINK;
    }

    return APP_STORE_LINK;
};

export default getDownloadLink;
