export enum OperatingSystem {
    Windows = 'Windows',
    macOS = 'macOS',
    Android = 'Android',
    iOS = 'iOS',
    Unknown = 'unknown'
}

export enum Store {
    AppStore = "appstore",
    GooglePlay = "googleplay"
}

export enum AppImage {
    Google = "google",
    Apple = "appStore"
}