import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import {
  addDate, addRiddleIds,
  changeScreen,
} from "../../../redux/restaurant/restaurantSlice";
import { RestaurantsButton } from "../../ui-kit/RestaurantsButton/RestaurantsButton";
import { DownloadApp } from "../DownloadApp/DownloadApp";
import successImg from "../../../assets/image/success-img.png";
import wrongImg from "../../../assets/image/wrong-img.png";
import { getAnalytics, logEvent } from "firebase/analytics";
import styles from "./AnswerResultsScreen.module.scss";
import getRestaurantIdFromUrl from "../../../utils/getRestaurant";

interface AnswerResultsProps {
  screen: string;
  riddleId?: string;
}

export const AnswerResultsScreen: FC<AnswerResultsProps> = ({ screen, riddleId }) => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const { rewardName } = useAppSelector((state) => state.restaurant.restaurant);
  const dispatch = useAppDispatch();

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("en-US", { month: "short" });
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  const formattedDate = `${month} ${day}, ${hours}:${formattedMinutes}`;
  const restaurantId = getRestaurantIdFromUrl();
  const handleButtonClick = () => {
    if (screen === "success") {
      dispatch(changeScreen("reward"));
      if(riddleId)dispatch(addRiddleIds(riddleId));
      logEvent(
        getAnalytics(),
        `web_riddle_attempt_successful_${restaurantId}`,
        {
          restaurantId: restaurantId,
        }
      );
      dispatch(addDate(formattedDate));
    } else {
      logEvent(getAnalytics(), `web_riddle_attempt_failed_${restaurantId}`, {
        restaurantId: restaurantId,
      });
      dispatch(changeScreen("main"));
    }
  };
  return (
    <main className={styles.main}>
      <DownloadApp show={isShown} setShow={setIsShown} />
      <div className={styles.mainContainer}>
        <img
          src={screen === "success" ? successImg : wrongImg}
          alt="ice cream puzzle"
          className={styles.mainImage}
        />
        <h2 className={styles.title}>
          {screen === "success"
            ? "You cracked the riddle!"
            : "Oops! Wrong Answer"}
        </h2>
        <p className={styles.text}>
          {screen === "success"
            ? rewardName
              ? "Well done! You solved this riddle and now you can take your Reward!"
              : "Well done! You solved this riddle and now you can try more!"
            : "But don't worry! We've got a special treat waiting just for you"}
        </p>
      </div>
      <RestaurantsButton
        text={
          screen === "success"
            ? rewardName
              ? "Reveal Reward"
              : "Continue"
            : "Try again"
        }
        onClick={handleButtonClick}
      />
    </main>
  );
};
