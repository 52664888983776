import { configureStore} from "@reduxjs/toolkit";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";


import restaurantSlice from "./restaurant/restaurantSlice";

const restaurantPersistConfig = {
  key:'restaurant',
  storage,
  whitelist:['restaurant', 'userName', 'activeScreen', 'date', 'riddleIds']
}




const restaurantPersistedReducer = persistReducer(
  restaurantPersistConfig,
  restaurantSlice,
)



const store = configureStore({
  reducer: {
 
     restaurant: restaurantPersistedReducer,
  
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  devTools: true,
  // devTools: process.env.NODE_ENV === 'development',
});

export default store;

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;



