 const firebaseConfig = {
  apiKey: "AIzaSyBVDaCMYFOusDW4t8VKNyHvuOzhmUzboGU",
  authDomain: "whoriddle-app.firebaseapp.com",
  projectId: "whoriddle-app",
  storageBucket: "whoriddle-app.appspot.com",
  messagingSenderId: "940256056570",
  appId: "1:940256056570:web:4f0a027e4bbd9d49e0e543",
  measurementId: "G-PRYZ104VD0",
};

export default firebaseConfig;
