import styles from './Banner.module.scss';

export enum BannerType {
    PINK = 'pink',
    GREEN = 'green',
}

type BannerProps = {
    title: string;
    subtitle1: string;
    subtitle2: string;
    bannerType: BannerType;
};

const Banner = ({title, subtitle1, subtitle2, bannerType}: BannerProps) => {
    const bannerClass = styles[bannerType];
    return (
        <div className={`${bannerClass} ${styles.banner}`}>
            <div className={styles.title}>{title}</div>
            <div
                dangerouslySetInnerHTML={{__html: subtitle1}}
                className={styles.subtitle}
            />
            <div
                className={styles.subtitle}
                dangerouslySetInnerHTML={{__html: subtitle2}}
            />
        </div>
    );
};

export default Banner;
