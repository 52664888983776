import {
  AnyAction,
  PayloadAction,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import { getRestaurantApi } from "../../api/fetchApi";
import { Restaurant, RestaurantState } from "../types";

const initialState: RestaurantState = {
  restaurant: {
    restaurantName: "",
    rewardName: "",
    rewardImage: "",
    rewardQr: "",
    isRewardAvailable:false,
    riddleTitle: "",
    riddleQuestion: "",
    riddleAnswer: "",
  },
  userName: "",
  activeScreen: "main",
  riddleIds:[],
  date:'',
  loading: false,
  error: null,
};

export const getRestaurant = createAsyncThunk<
  Restaurant,
  { restaurantsId: string | undefined , riddleId: string | undefined},
  { rejectValue: string }
>("restaurant/getRestaurant", async (params, { rejectWithValue }) => {
  const data = await getRestaurantApi(params.restaurantsId, params.riddleId);


  if (data === 404) {
    return rejectWithValue("Restaurant not found");
  } else if (data === 500) {
    return rejectWithValue("Internal Server Error");
  }
  return data;
});

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    addUserName(state, action: PayloadAction<string>) {
      state.userName = action.payload;
    },
    changeScreen(state, action: PayloadAction<string>) {
      state.activeScreen = action.payload;
    },
    addDate(state, action: PayloadAction<string>) {
      state.date = action.payload;
    },
    addRiddleIds(state, action: PayloadAction<string>) {
      const riddleIdToAdd = action.payload;
      if (!state.riddleIds.includes(riddleIdToAdd)) {
        state.riddleIds = [...state.riddleIds, riddleIdToAdd];
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRestaurant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRestaurant.fulfilled, (state, action) => {
        state.loading = false;
        state.restaurant = action.payload;
      })
      .addMatcher(isError, (state, action: PayloadAction<string>) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { addUserName, changeScreen, addDate, addRiddleIds } = restaurantSlice.actions;
export default restaurantSlice.reducer;

function isError(action: AnyAction) {
  return action.type.endsWith("rejected");
}
