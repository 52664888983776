import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import downloadImage from "../../../assets/image/who-riddle download.png";
import sprite from "../../../assets/sprite.svg";
import styles from "./DownloadApp.module.scss";

interface DownloadAppProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DownloadApp: FC<DownloadAppProps> = ({ show, setShow }) => {
  const [appLink, setAppLink] = useState<string>("");
  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
      setAppLink(
        "https://play.google.com/store/apps/details?id=com.whoriddle.v2"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setAppLink("https://apps.apple.com/ng/app/whoriddle/id1638671136");
    }
  }, []);
  const visible = {
    opacity: 1,
    display: "block",
  };

  const hide = {
    opacity: 0,
    transitionEnd: {
      display: "none",
    },
  };
  return (
    <motion.div animate={show ? visible : hide}>
      <div className={styles.container}>
        <div className={styles.contextContainer}>
          <svg onClick={() => setShow(false)} className={styles.icon}>
            <use href={sprite + "#close"}></use>
          </svg>
          <img src={downloadImage} alt="download" className={styles.image} />
          <div className={styles.textContainer}>
            <h3 className={styles.title}>Download our app</h3>
            <p className={styles.text}>
              Get our app for extra puzzles and easy enjoyment.
            </p>
          </div>
          <a
            target="_blank"
            href={appLink}
            rel="noopener noreferrer"
            className={styles.link}
          >
            Download
          </a>
        </div>
      </div>
    </motion.div>
  );
};
