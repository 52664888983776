import styles from './Road.module.scss';
import RoadImage from '../../../assets/image/DownloadPage/CompletedRoad.png';
import Maskot from '../../../assets/image/DownloadPage/Maskot_Road.png';

const Road = () => {
    return (
        <div className={styles.road}>
            <img src={RoadImage} alt="road" className={styles.roadImage} />
            <img src={Maskot} alt="maskot" className={styles.maskot} />
        </div>
    );
};

export default Road;
