import React, { useEffect, useState } from "react";
import { ImageFlip } from "../ImageFlip/ImageFlip";
import AppStore from "../../../assets/image/App Store.svg";
import Google from "../../../assets/image/Google Play.svg";
import iceGreen from "../../../assets/image/ice-green.png";
import redConfetti from "../../../assets/image/confetti-red.svg";
import turquoiseConfetti from "../../../assets/image/confetti-turquoise.svg";
import spiralConfetti from "../../../assets/image/confetti-spiral.svg";
import dotConfetti from "../../../assets/image/confetti-dot.svg";
import lineConfetti from "../../../assets/image/confetti-line.svg";
import orangeConfetti from "../../../assets/image/confetti-orange.svg";
import userIcon from "../../../assets/image/user-icon-rest.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import styles from "./RewardScreen.module.scss";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { ClickNames } from "../../../types/enum/Events";
import { AppImage } from "../../../types/enum/OperatingSystem";
import getRestaurantIdFromUrl from "../../../utils/getRestaurant";

export const RewardScreen = () => {
  const [appLink, setAppLink] = useState<string>("");
  const [appImage, setAppImage] = useState<string>("");
  const { userName, date } = useAppSelector((state) => state.restaurant);
  const { rewardName } = useAppSelector((state) => state.restaurant.restaurant);
  const restaurantId = getRestaurantIdFromUrl();

  useEffect(() => {
    const userAgent =
      navigator.userAgent || navigator.vendor || (window as any).opera;

    if (/android/i.test(userAgent)) {
      setAppLink(
        "https://play.google.com/store/apps/details?id=com.whoriddle.v2"
      );
      setAppImage(AppImage.Google);
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      setAppLink("https://apps.apple.com/ng/app/whoriddle/id1638671136");
      setAppImage(AppImage.Apple);
    }
  }, []);
  const handleDownloadClick = () => {
    if (appImage === AppImage.Google) {
      logEvent(getAnalytics(), ClickNames.Android, {
        platform: "android",
      });
      logEvent(getAnalytics(), `google_play_click_${restaurantId}`, {
        restaurantId: restaurantId,
      });
    } else {
      logEvent(getAnalytics(), ClickNames.Ios, {
        platform: "ios",
      });
      logEvent(getAnalytics(), `app_store_click_${restaurantId}`, {
        restaurantId: restaurantId,
      });
    }
  };

  return (
    <main className={styles.main}>
      <div className={styles.mainContainer}>
        {rewardName ? (
          <>
            <h2 className={styles.title}>
              Great Job, {userName}! You just solved your first riddle. Please
              show your server this screen and you will get a free {rewardName}!
            </h2>
            <ImageFlip />
          </>
        ) : (
          <h2 className={styles.title}>
            Great job. You just solved your first riddle!
          </h2>
        )}
      </div>

      <div className={styles.downloadContainer}>
        <h2 className={styles.downloadTitle}>Download WhoRiddle</h2>
        <p className={styles.downloadText}>
          For more riddle fun.
          <br /> Redeem points and win prizes.
        </p>
        <a
          target="_blank"
          href={appLink}
          rel="noopener noreferrer"
          onClick={handleDownloadClick}
        >
          {appImage === "google" ? (
            <img
              src={Google}
              className={styles.downloadGoogle}
              alt="App Store"
            />
          ) : (
            <img
              src={AppStore}
              className={styles.downloadAppStore}
              alt="App Store"
            />
          )}
        </a>
        <img src={iceGreen} className={styles.downloadImage} alt="green" />
        <img src={redConfetti} className={styles.redConfetti} alt="green" />
        <img src={redConfetti} className={styles.redConfettiTwo} alt="green" />
        <img
          src={turquoiseConfetti}
          className={styles.turquoiseConfetti}
          alt="green"
        />
        <img
          src={turquoiseConfetti}
          className={styles.turquoiseConfettiTwo}
          alt="green"
        />
        <img
          src={turquoiseConfetti}
          className={styles.turquoiseConfettiThree}
          alt="green"
        />
        <img
          src={spiralConfetti}
          className={styles.spiralConfetti}
          alt="green"
        />
        <img
          src={spiralConfetti}
          className={styles.spiralConfettiTwo}
          alt="green"
        />
        <img src={dotConfetti} className={styles.dotConfetti} alt="green" />
        <img src={dotConfetti} className={styles.dotConfettiTwo} alt="green" />
        <img src={lineConfetti} className={styles.lineConfetti} alt="green" />
        <img
          src={orangeConfetti}
          className={styles.orangeConfetti}
          alt="green"
        />
      </div>
      <div className={styles.userContainer}>
        <div className={styles.nameContainer}>
          <img src={userIcon} className={styles.userIcon} alt="green" />
          <p className={styles.userName}>{userName}</p>
        </div>

        <p className={styles.date}>{date}</p>
      </div>
    </main>
  );
};
