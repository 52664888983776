import React from "react";
import logo from '../../../logo.gif'
import styles from './RestaurantsLoader.module.scss'

export const RestaurantsLoader = () => {
    return ( 
        <main className={styles.main}>
        <img src={logo} alt="logo" className={styles.logo}/>
        </main>
    )
}