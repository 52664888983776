import React, { FC } from "react";
import wrongImg from "../../../assets/image/wrong-img.png";
import styles from './RestaurantsError.module.scss'


interface RestaurantsErrorProps {
    errorText: string
   }
   
export const RestaurantsError: FC<RestaurantsErrorProps> = ({errorText}) => {
return(
    <main className={styles.main}>
         <img
          src={wrongImg}
          alt="ice cream puzzle"
          className={styles.mainImage}
        />
        <h2 className={styles.title}>
        Oops! {errorText}
        </h2>
    </main>
)
}