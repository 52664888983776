import React, { FC } from "react";
import styles from "./RestaurantsButton.module.scss";

interface RestaurantsButtonProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
}

export const RestaurantsButton: FC<RestaurantsButtonProps> = ({
  text,
  onClick,
  disabled,
}) => {
  return (
    <button className={`${styles.button} ${disabled ? styles.disable : ''}`} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
};
