import styles from "./Branding.module.scss";
import logo from "../../assets/logo.png";
import React from "react";
import { Link } from "react-router-dom";
import platform from "platform";
import { getAnalytics, logEvent } from "firebase/analytics";
import {ClickNames} from "../../types/enum/Events";
import  {OperatingSystem} from  "../../types/enum/OperatingSystem"

export const Branding = () => {
  const getOperatingSystem = () => {
    const osFamily = platform.os?.family?.toLowerCase() || '';
    const operatingSystems = [OperatingSystem.Windows, OperatingSystem.macOS, OperatingSystem.Android, OperatingSystem.iOS];
    const detectedOS = operatingSystems.find(os => osFamily.includes(os.toLowerCase()));
    return detectedOS || OperatingSystem.Unknown;
  };

    const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.whoriddle.v2';
    const IOS_APP_URL = 'https://apps.apple.com/ng/app/whoriddle/id1638671136';

    const handleDownload = () => {
        switch (getOperatingSystem()) {
            case OperatingSystem.Windows:
                window.open(ANDROID_APP_URL, '_blank');
                logEvent(getAnalytics(), ClickNames.Android);
                break;
            case OperatingSystem.macOS:
                window.open(IOS_APP_URL, '_blank');
                logEvent(getAnalytics(), ClickNames.Ios);
                break;
            case OperatingSystem.Android:
                window.open(ANDROID_APP_URL, '_blank');
                logEvent(getAnalytics(), ClickNames.Android);
                break;
            case OperatingSystem.iOS:
                window.open(IOS_APP_URL, '_blank');
                logEvent(getAnalytics(), ClickNames.Ios);
                break;
            default:
                window.open(IOS_APP_URL, '_blank');
                logEvent(getAnalytics(), ClickNames.Ios);
        }
    };


    return (
    <div className={styles.brandingContainer}>
      <div className={styles.logoContainer}>
        <div className={styles.logo}>
          <img src={logo} alt="logo" className={styles.logo} />
        </div>
        <h2 className={styles.logoHeader}>WhoRiddle</h2>
      </div>
      <div className={styles.interaction}>
        <Link to={"/privacy-policy"} className={styles.interactionItem}>
          Privacy Policy
        </Link>
        <Link
          to={"/terms-of-use"}
          className={`${styles.interactionItem} ${styles.termOfUse}`}
        >
          Term of Use
        </Link>
        <a
          href={`mailto:whoriddle40@gmail.com`}
          className={styles.interactionItem}
        >
          Contact
        </a>
      </div>
      <div>
        <button onClick={handleDownload} className={styles.button}>
          Download App
        </button>
      </div>
    </div>
  );
};