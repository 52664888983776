import axios from 'axios';

const apiKey = process.env.REACT_APP_BASE_URL;

axios.defaults.baseURL = apiKey;

export async function getRestaurantApi(
    restaurantId: string | undefined,
    riddleId: string | undefined
) {
    return axios
        .get(`/restaurant/${restaurantId}/${riddleId}`)
        .then((res) => {
            return res.data.data;
        })
        .catch((error) => {
            if (error.response) {
                return error.response.status;
            } else {
                return Promise.reject(new Error('API request failed'));
            }
        });
}
