import Main from '../../component/DownloadAppPage/Main/Main';
import Banner from '../../component/DownloadAppPage/Banner/Banner';
import styles from './DownloadAppPage.module.scss';
import Road from '../../component/DownloadAppPage/Road/Road';
import DownloadBanner from '../../component/DownloadAppPage/DownloadBanner/DownloadBanner';
import {BannerType} from '../../component/DownloadAppPage/Banner/Banner';
import LicenseLogo from '../../component/DownloadAppPage/LicenseLogo/LicenseLogo';

export const DownloadAppPage = () => {
    return (
        <div className={styles.main}>
            <div style={{marginBottom: 16}}>
                <Main />
            </div>
            <div style={{marginBottom: 42}}>
                <Banner
                    title="Hey, Riddle Lovers!"
                    subtitle1="Glad you’re here. Look around."
                    subtitle2={`See something you like? Follow <br /> these steps to make it yours:`}
                    bannerType={BannerType.PINK}
                />
            </div>
            <Road />
            <div style={{marginBottom: 24}}>
                <Banner
                    title="Сan’t solve them all?"
                    subtitle1="We still have something <br/> for you. Just ask."
                    subtitle2={``}
                    bannerType={BannerType.GREEN}
                />
            </div>
            <div style={{marginBottom: 64}}>
                <DownloadBanner />
            </div>
            <LicenseLogo />
        </div>
    );
};
