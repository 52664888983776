import React, { ChangeEvent, useState } from "react";

import styles from "./EnteringName.module.scss";
import { RestaurantsButton } from "../../../ui-kit/RestaurantsButton/RestaurantsButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { addUserName } from "../../../../redux/restaurant/restaurantSlice";

export const EnteringName = () => {
  const [value, setValue] = useState<string>("");
  const { rewardName, isRewardAvailable } = useAppSelector(
    (state) => state.restaurant.restaurant
  );
  const dispatch = useAppDispatch();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const handleButtonClick = () => {
    dispatch(addUserName(value));
  };

  return (
    <>
      <div className={styles.container}>
        {rewardName ? (
          <h2 className={styles.title}>
            Solve the Riddle, Claim <br />
            Your Rewards!
          </h2>
        ) : (
          <h2 className={styles.title}>
            Solve the riddle, get rewards
          </h2>
        )}
        <p className={styles.text}>
          Ready to unravel the mystery? Begin by <br />
          entering your name!
        </p>
        <input
          type="text"
          name="name"
          placeholder="Your name"
          value={value}
          onChange={handleInputChange}
          className={styles.input}
        />
      </div>
      <RestaurantsButton
        text="Let’s Start!"
        onClick={handleButtonClick}
        disabled={value.length === 0}
      />
    </>
  );
};
