import styles from './DownloadBanner.module.scss';
import AppStore from '../../../assets/image/DownloadPage/AppStore.png';
import GooglePlay from '../../../assets/image/DownloadPage/PlayMarket.png';
import Maskot from '../../../assets/image/DownloadPage/Maskot.png';
import Confeti from '../../../assets/image/DownloadPage/Confeti.png';
import {
    APP_STORE_LINK,
    GOOGLE_PLAY_LINK,
} from '../../../constants/downloadLinks';

const DownloadBanner = () => {
    return (
        <div className={styles.downloadBanner}>
            <div className={styles.title}>Download WhoRiddle</div>
            <div className={styles.bannerWrapper}>
                <a
                    style={{
                        zIndex: 4,
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={APP_STORE_LINK}
                >
                    <img
                        src={AppStore}
                        className={styles.banner}
                        alt="AppStore"
                    />
                </a>
                <a
                    style={{
                        zIndex: 4,
                    }}
                    target="_blank"
                    rel="noreferrer"
                    href={GOOGLE_PLAY_LINK}
                >
                    <img
                        src={GooglePlay}
                        className={styles.banner}
                        alt="GooglePlay"
                    />
                </a>
            </div>
            <img src={Maskot} className={styles.maskot} alt="Maskot" />
            <img src={Confeti} className={styles.confeti} alt="Confeti" />
        </div>
    );
};

export default DownloadBanner;
