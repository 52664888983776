import React, { ChangeEvent, useState } from "react";
import { RestaurantsButton } from "../../../ui-kit/RestaurantsButton/RestaurantsButton";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { changeScreen } from "../../../../redux/restaurant/restaurantSlice";

import styles from "./Riddle.module.scss";
import { compareAnswers } from "../../../../utils/riddle";
import sprite from "../../../../assets/sprite.svg";
import Hint from "../Hint/Hint";

export const Riddle = () => {
  const [value, setValue] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { riddleTitle, riddleAnswer, riddleQuestion } = useAppSelector(
    (state) => state.restaurant.restaurant
  );
  const dispatch = useAppDispatch();

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };
  const handleButtonClick = () => {
    const isAnswerCorrect = compareAnswers(value, riddleAnswer);

    if (isAnswerCorrect) {
      dispatch(changeScreen("success"));
    } else {
      dispatch(changeScreen("wrong"));
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <h2 className={styles.title}>{riddleTitle}</h2>
          <p className={styles.text}>{riddleQuestion}</p>
          <div className={styles.title_container}>
          <h3 className={styles.inputTitle}>Who am I?</h3>
            {/*TODO: Add a hint modal when there will be a design*/}
            {/*<div className={styles.hintContainer} onClick={() => setIsOpen(true)}>*/}
            {/*  <svg  className={styles.icon}>*/}
            {/*    <use href={sprite + "#hint"}></use>*/}
            {/*  </svg>*/}
            {/* <p className={styles.hintText}>Use hint (2)</p>*/}
            {/*</div>*/}
          </div>
          <input
              type="text"
              name="answer"
              placeholder="Write your answer..."
            value={value}
            onChange={handleInputChange}
            className={styles.input}
          />
        </div>
      </div>
      <RestaurantsButton
        text="Solve!"
        disabled={value.length === 0}
        onClick={handleButtonClick}
      />
      {/*TODO: Add a hint modal when there will be a design*/}
      {/*{isOpen && <Hint setIsOpen={setIsOpen} />}*/}
    </>
  );
};
