import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  changeScreen,
  getRestaurant,
} from "../../redux/restaurant/restaurantSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hook";
import { MainScreen } from "./MainScreen/MainScreen";
import { AnswerResultsScreen } from "./AnswerResultsScreen/AnswerResultsScreen";
import { RewardScreen } from "./RewardScreen/RewardScreen";
import { motion, AnimatePresence } from "framer-motion";
import { RestaurantsLoader } from "../ui-kit/RestaurantsLoader/RestaurantsLoader";
import { RestaurantsError } from "../ui-kit/RestaurantsError/RestaurantsError";
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../../config/firebaseConfig";

export const RestaurantsPage = () => {
  const { restaurantsId, riddleId } = useParams();

  const dispatch = useAppDispatch();
  const { loading, error, activeScreen, riddleIds } = useAppSelector(
    (state) => state.restaurant
  );
  const { restaurantName } = useAppSelector(
    (state) => state.restaurant.restaurant
  );

  useEffect(() => {
    dispatch(getRestaurant({ restaurantsId, riddleId }));
    if (riddleId) {
      const checkingForPassingRiddle = riddleIds.includes(riddleId);
      if (checkingForPassingRiddle) {
        dispatch(changeScreen("reward"));
      } else {
        dispatch(changeScreen("main"));
      }
    }
  }, [restaurantsId, riddleId]);

  const logRestaurantViewEvent = () => {
    const currentUrl = window.location.href;
    const match = currentUrl.match(/\/restaurant\/(\d+)/);
    const restaurantId = match ? parseInt(match[1]) : null;

    if (!restaurantId || isNaN(restaurantId)) return;

    const analytics = getAnalytics(initializeApp(firebaseConfig));
    logEvent(analytics, `web_riddle_view_${restaurantId}`, {
      restaurantId,
    });
  };

  useEffect(() => {
    logRestaurantViewEvent();
  }, []);

  return (
    <>
      {loading ? (
        <RestaurantsLoader />
      ) : restaurantName === "" ? (
        <RestaurantsError errorText="Restaurant not found" />
      ) : error ? (
        <RestaurantsError errorText={error} />
      ) : (
        <AnimatePresence
          mode="wait"
          initial={false}
          onExitComplete={() => {
            window.scrollTo(0, 0);
          }}
        >
          <motion.div
            key={activeScreen}
            initial={{ x: 10, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -10, opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            {activeScreen === "main" ? (
              <MainScreen />
            ) : activeScreen === "reward" ? (
              <RewardScreen/>
            ) : (
              <AnswerResultsScreen screen={activeScreen} riddleId={riddleId}/>
            )}
          </motion.div>
        </AnimatePresence>
      )}
    </>
  );
};
